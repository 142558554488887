@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
.HomePage-workshopDetail {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 700px) {
    .HomePage-workshopDetail {
        width: 70%;
    }
}
*, *:before, *:after {
  box-sizing: border-box;
  font-family: 'Roboto',  sans-serif;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto',  sans-serif;
}

p {
  display: block;
  /*margin-block-start: 1em;*/
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  /*overflow-y: scroll;*/
}

html{
  overflow-y: scroll;
}

/*h2 {
    padding-bottom: .3em;
    font-size: 1.5em;
    line-height: 1.334;
    border-bottom: 1px solid #eee;
}*/

.workshop-title {
    padding-bottom: 0pt;
    padding-top: 0pt;
    margin-bottom: 0pt;
    font-weight: 500;
    font-size: 3rem;
    text-align: center;
}

.workshop-subtitle {
    color: #666666;
    font-weight: 400;
    font-size: 1.45rem;
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.workshop-h1 {
    font-weight: 600;
    font-size: 2.4rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    text-align: left;
}

.workshop-h2 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: .6rem;
    text-align: left;
    font-style: italic;
}

.workshop-h3 {
    font-weight: 500;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: .6rem;
    text-align: left;
}

.workshop-p {
    font-size: 1rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
    text-align: left;
}

.workshop-ul {
    margin-left: 2rem;
}

.workshop-li {
    text-align: left;
}

body {
    counter-reset: h2counter;
}

h1 {
    counter-reset: h2counter;
}

/*h2.workshop-h2:before {*/
    /*content: counter(h2counter) ".\0000a0\0000a0";*/
    /*counter-increment: h2counter;*/
    /*counter-reset: h3counter;*/
/*}*/

/*h3:before {*/
    /*content: counter(h2counter) "." counter(h3counter) ".\0000a0\0000a0";*/
    /*counter-increment: h3counter;*/
/*}*/
