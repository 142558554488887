.HomePage-workshopDetail {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 700px) {
    .HomePage-workshopDetail {
        width: 70%;
    }
}