@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

*, *:before, *:after {
  box-sizing: border-box;
  font-family: 'Roboto',  sans-serif;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto',  sans-serif;
}

p {
  display: block;
  /*margin-block-start: 1em;*/
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  /*overflow-y: scroll;*/
}

html{
  overflow-y: scroll;
}

/*h2 {
    padding-bottom: .3em;
    font-size: 1.5em;
    line-height: 1.334;
    border-bottom: 1px solid #eee;
}*/
