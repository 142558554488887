.workshop-title {
    padding-bottom: 0pt;
    padding-top: 0pt;
    margin-bottom: 0pt;
    font-weight: 500;
    font-size: 3rem;
    text-align: center;
}

.workshop-subtitle {
    color: #666666;
    font-weight: 400;
    font-size: 1.45rem;
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.workshop-h1 {
    font-weight: 600;
    font-size: 2.4rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    text-align: left;
}

.workshop-h2 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: .6rem;
    text-align: left;
    font-style: italic;
}

.workshop-h3 {
    font-weight: 500;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: .6rem;
    text-align: left;
}

.workshop-p {
    font-size: 1rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
    text-align: left;
}

.workshop-ul {
    margin-left: 2rem;
}

.workshop-li {
    text-align: left;
}

body {
    counter-reset: h2counter;
}

h1 {
    counter-reset: h2counter;
}

/*h2.workshop-h2:before {*/
    /*content: counter(h2counter) ".\0000a0\0000a0";*/
    /*counter-increment: h2counter;*/
    /*counter-reset: h3counter;*/
/*}*/

/*h3:before {*/
    /*content: counter(h2counter) "." counter(h3counter) ".\0000a0\0000a0";*/
    /*counter-increment: h3counter;*/
/*}*/